<template>
  <div>
    <!-- page header component -->
    <page-header
      name="activities"
      icon="playlist-check"
    >
      <template #actions>
        <div class="level-item">
          <search-bar />
        </div>

        <div class="level-item">
          <b-dropdown aria-role="list">
            <b-button
              slot="trigger"
              type="is-info"
              icon-left="plus"
              icon-right="chevron-down"
            >
              create
            </b-button>
            <template v-if="hasPermissions('create_accounts')">
              <b-dropdown-item
                has-link
                aria-role="menuitem"
              >
                <router-link to="/accounts/add">
                  Account
                </router-link>
              </b-dropdown-item>
              <hr class="dropdown-divider">
            </template>

            <template v-if="hasPermissions('create_contacts')">
              <b-dropdown-item
                has-link
                aria-role="menuitem"
              >
                <router-link to="/contacts/add">
                  Contact
                </router-link>
              </b-dropdown-item>
              <hr class="dropdown-divider">
            </template>

            <template v-if="hasPermissions('create_leads')">
              <b-dropdown-item
                has-link
                aria-role="menuitem"
              >
                <router-link to="/leads/add">
                  Lead
                </router-link>
              </b-dropdown-item>
              <hr class="dropdown-divider">
            </template>

            <template v-if="hasPermissions('create_leads')">
              <b-dropdown-item
                has-link
                aria-role="menuitem"
              >
                <router-link to="/opportunities/add">
                  Opportunity
                </router-link>
              </b-dropdown-item>
              <hr class="dropdown-divider">
            </template>

            <b-dropdown-item
              v-if="hasPermissions('create_activities')"
              has-link
              aria-role="menuitem"
            >
              <router-link to="/activities/add">
                Activity
              </router-link>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </page-header>

    <section class="section">
      <div class="container">
        <b-breadcrumb size="is-small">
          <b-breadcrumb-item
            tag="router-link"
            to="/profile"
          >
            Home
          </b-breadcrumb-item>
          <b-breadcrumb-item
            tag="router-link"
            to="/activities"
            active
          >
            Activities
          </b-breadcrumb-item>
        </b-breadcrumb>

        <b-tabs v-model="activeTab">
          <b-tab-item label="grid view">
            <activities-table @notification="openNotificationModal" />
          </b-tab-item>

          <b-tab-item label="list view">
            <!-- loading component -->
            <b-loading
              v-model="isLoading"
              :is-full-page="false"
            />

            <div class="columns">
              <div class="column is-three-fifths">
                <activities-list
                  @remove-activity="deleteActivity"
                  @mark-as-complete="markAsComplete"
                />
              </div>
            </div>
          </b-tab-item>

          <b-tab-item label="calendar view">
            <!-- loading component -->
            <b-loading
              v-model="isLoading"
              :is-full-page="false"
            />
            <activities-calendar />
          </b-tab-item>
        </b-tabs>
      </div>
    </section>

    <!-- notifications form modal component -->
    <b-modal
      v-model="isNotificationsModalActive"
      :width="400"
      scroll="keep"
    >
      <notifications-form-modal
        @close="isNotificationsModalActive = false"
        @save-notification="updateActivity"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { PageHeader } from '@/components/Shared';
import {
  ActivitiesCalendar,
  ActivitiesList,
  ActivitiesTable,
  NotificationsFormModal,
} from '@/components/Activities';
import { SearchBar } from '@/components/Search';

export default {
  name: 'ListActivities',

  components: {
    PageHeader,
    ActivitiesCalendar,
    ActivitiesList,
    ActivitiesTable,
    NotificationsFormModal,
    SearchBar,
  },

  data: () => ({
    activeTab: 0,
    activityId: '',
    isNotificationsModalActive: false,
    userId: localStorage.getItem('user_id'),
    isLoading: false,
  }),

  computed: {
    ...mapGetters({
      activity: 'Activities/getActivity',
    }),
  },

  watch: {
    activeTab: {
      handler(value) {
        if (value === 2) {
          setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
          }, 1);
        }
      },

      deep: true,
    },
  },

  methods: {
    openNotificationModal(payload) {
      this.activityId = payload.id;
      this.$store.commit('Activities/SET_ACTIVITY', payload);
      this.isNotificationsModalActive = true;
    },

    async updateActivity() {
      this.isLoading = true;
      try {
        const payload = { ...this.activity };

        if (payload.file.fileName === '') {
          payload.file = null;
        }

        if (payload.reminder.remindAtId === '') {
          payload.reminder = null;
        }

        if (payload.meetingDate) {
          const date = new Date(payload.meetingDate).getDate() < 10
            ? `0${new Date(payload.meetingDate).getDate()}`
            : new Date(payload.meetingDate).getDate();
          const month = new Date(payload.meetingDate).getMonth() + 1 < 10
            ? `0${new Date(payload.meetingDate).getMonth() + 1}`
            : new Date(payload.meetingDate).getMonth() + 1;
          const year = new Date(payload.meetingDate).getFullYear();

          payload.meetingDate = `${year}-${month}-${date}`;
        }

        if (payload.meetingStartTime) {
          payload.meetingStartTime = payload.meetingStartTime.toLocaleTimeString('it-IT');
        }

        if (payload.meetingEndTime) {
          payload.meetingEndTime = payload.meetingEndTime.toLocaleTimeString('it-IT');
        }

        if (this.hasPermissions('update_activities')) {
          await this.$store.dispatch('Activities/updateActivity', {
            activityId: this.activityId,
            activity: payload,
          });
        }
        this.NotificationsFormModal = false;
        this.$store.commit('Activities/CLEAR_ACTIVITY');
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },

    async markAsComplete(activity) {
      this.isLoading = true;
      try {
        await this.$store.dispatch('Activities/fetchActivity', activity.activityId);
        this.$store.commit('Activities/SET_ACTIVITY_IS_COMPLETE', true);
        const payload = { ...this.activity };
        if (payload.file.fileName === '') {
          payload.file = null;
        }
        if (payload.reminder.remindAtId === '') {
          payload.reminder = null;
        }
        if (this.hasPermissions('update_activities')) {
          await this.$store.dispatch('Activities/updateActivity', {
            activityId: activity.activityId,
            activity: payload,
          });
        }
        await this.$store.dispatch('DataGrids/fetchActivitiesList', {
          page: activity.current - 1,
          size: activity.perPage,
          sort: ['updatedAt', 'desc'],
        });
        this.$store.commit('Activities/CLEAR_ACTIVITY');
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },

    async deleteActivity(payload) {
      this.isLoading = true;
      try {
        await this.$store.dispatch('Activities/deleteActivity', payload.activityId);
        await this.$store.dispatch('DataGrids/fetchActivitiesList', {
          page: payload.current - 1,
          size: payload.perPage,
          sort: ['updatedAt', 'desc'],
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style></style>
