<template>
  <div>
    <!-- loading component -->
    <b-loading
      v-model="isLoading"
      :is-full-page="false"
    />

    <page-header
      icon="playlist-check"
      name="add activity"
    />

    <section class="section">
      <div class="container">
        <div class="columns">
          <div class="column is-three-fifths">
            <!-- activities form component -->
            <activities-form
              :activity-types="activityTypes"
              :event-types="eventTypes"
              :task-types="taskTypes"
              :task-priorities="taskPriorities"
              :invited="invited"
              :timezones="timezones"
              @open-notifications-modal="isNotificationsModalActive = true"
              @save-activity="createActivity"
            />
          </div>
        </div>
      </div>
    </section>

    <!-- notifications form modal component -->
    <b-modal
      v-model="isNotificationsModalActive"
      :width="400"
      scroll="keep"
    >
      <notifications-form-modal
        @close="isNotificationsModalActive = false"
        @save-notification="isNotificationsModalActive = false"
      />
    </b-modal>
  </div>
</template>

<script>
import ActivitiesMixin from '@/mixins/ActivitiesMixin';
import { PageHeader } from '@/components/Shared';
import { ActivitiesForm, NotificationsFormModal } from '@/components/Activities';

export default {
  name: 'AddActivity',

  components: {
    ActivitiesForm,
    PageHeader,
    NotificationsFormModal,
  },

  mixins: [ActivitiesMixin],

  methods: {
    async createActivity() {
      const payload = { ...this.activity };
      try {
        payload.file = null;
        if (payload.reminder.remindAtId === '') {
          payload.reminder = null;
        }

        await this.$store.dispatch('Activities/createActivity', payload);

        if (this.$route.query.accountId) {
          await this.$router.push(`/accounts/${this.$route.query.accountId}/view?activeTab=4`);
          return;
        }

        if (this.$route.query.contactId) {
          await this.$router.push(`/contacts/${this.$route.query.contactId}/view?activeTab=4`);
          return;
        }

        if (this.$route.query.leadId) {
          await this.$router.push(`/leads/${this.$route.query.leadId}/view?activeTab=1`);
          return;
        }

        if (this.$route.query.opportunityId) {
          await this.$router.push(`/opportunities/${this.$route.query.opportunityId}/view?activeTab=1`);
          return;
        }

        if (Object.keys(this.$route.query).length === 0) {
          await this.$router.push('/activities');
          return;
        }

        return;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style>

</style>
