<template>
  <div>
    <!-- page header component -->
    <page-header
      name="edit activity"
      icon="playlist-check"
    />

    <section class="section">
      <div class="container">
        <!-- loading component -->
        <b-loading
          v-model="isLoading"
          :is-full-page="true"
        />

        <div class="columns">
          <div class="column is-three-fifths">
            <!-- activities form component -->
            <activities-form
              :activity-types="activityTypes"
              :event-types="eventTypes"
              :task-types="taskTypes"
              :task-priorities="taskPriorities"
              :timezones="timezones"
              :invited="invited"
              @open-notifications-modal="isNotificationsModalActive = true"
              @save-activity="updateActivity"
            />
          </div>
        </div>
      </div>
    </section>

    <!-- notifications form modal component -->
    <b-modal
      v-model="isNotificationsModalActive"
      :width="400"
      scroll="keep"
    >
      <notifications-form-modal
        @close="isNotificationsModalActive = false"
        @save-notification="isNotificationsModalActive = false"
      />
    </b-modal>
  </div>
</template>

<script>
import ActivitiesMixin from '@/mixins/ActivitiesMixin';

import { ActivitiesForm, NotificationsFormModal } from '@/components/Activities';
import { PageHeader } from '@/components/Shared';

export default {
  name: 'EditActivity',

  components: {
    ActivitiesForm,
    NotificationsFormModal,
    PageHeader,
  },

  mixins: [ActivitiesMixin],

  computed: {
    isActive: {
      get() {
        return this.$store.state.Activities.activity.isActive;
      },

      set(value) {
        return this.$store.commit('Activities/SET_ACTIVITY_IS_ACTIVE', value);
      },
    },
  },

  methods: {
    async updateActivity() {
      this.isLoading = true;
      const payload = { ...this.activity };
      if (payload.file.fileName === '') {
        payload.file = null;
      }
      if (payload.reminder.remindAtId === '') {
        payload.reminder = null;
      }

      if (payload.dueDate) {
        // save the date time string representation of dueDate string value to the dueDate payload
        payload.dueDate = payload.dueDate.split(' ').join('T');
      }

      try {
        await this.$store.dispatch('Activities/updateActivity', {
          activityId: this.$route.params.activityId,
          activity: payload,
        });
        if (this.$route.params.redirectParams) {
          await this.$router.push(this.$route.params.redirectParams);
        } else {
          await this.$router.push('/activities');
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>

</style>
