import { mapGetters, mapActions } from 'vuex';

export default {
  data: () => ({
    isLoading: true,
    isNotificationsModalActive: false,
  }),

  computed: mapGetters({
    activity: 'Activities/getActivity',
    invited: 'Activities/getActivityInvited',
    activityTypes: 'Lookups/getActivityTypes',
    eventTypes: 'Lookups/getEventTypes',
    taskTypes: 'Lookups/getTaskTypes',
    taskPriorities: 'Lookups/getTaskPriorities',
    timezones: 'Lookups/getTimezones',
  }),

  async created() {
    try {
      if (this.$route.name === 'EditActivity') {
        await this.fetchActivity(this.$route.params.activityId);
      }
      await this.fetchActivityTypes();
      await this.fetchEventTypes();
      await this.fetchTaskTypes();
      await this.fetchTaskPriorities();
      await this.fetchTimezones();
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  },

  destroyed() {
    this.$store.commit('Activities/CLEAR_ACTIVITY');
  },

  methods: {
    ...mapActions({
      fetchActivity: 'Activities/fetchActivity',
      fetchActivityTypes: 'Lookups/fetchActivityTypes',
      fetchEventTypes: 'Lookups/fetchEventTypes',
      fetchTaskTypes: 'Lookups/fetchTaskTypes',
      fetchTaskPriorities: 'Lookups/fetchTaskPriorities',
      fetchTimezones: 'Lookups/fetchTimezones',
    }),
  },

};
